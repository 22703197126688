// Header.js
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import {
    clearLocalStorage,
    getAllReports,
    getGoogleKeyOrMsg,
    getLocalStorageItem,
    hasPermission,
    isCompanyAdmin,
    isSuperAdmin,
    setLocalStorageItem,
} from "../../helpers/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon
import {
    faUser,
    faCog,
    faHardDrive,
    faSignOutAlt,
    faFileInvoice,
    faHome,
    faBuilding,
    faBuildingUser,
    faMap,
    faUserPlus,
    faCashRegister,
    faMoneyBill,
    faWalkieTalkie,
    faCalendarCheck,
    faListCheck,
    faMapLocation,
    faUsers,
    faPersonWalkingLuggage,
    faAddressBook,
    faChartSimple,
    faTv,
    faLocationArrow,
    faBuildingCircleExclamation,
    faWarehouse,
    faCircleInfo,
    faPersonRifle,
    faMapLocationDot,
    faEnvelope,
    faCalendarAlt,
    faListAlt,
    faBell,
} from "@fortawesome/free-solid-svg-icons"; // Import the necessary icons
import { get } from "../../helpers/api";
import Loader from "../Loader/Loader";
import $ from "jquery";
import { toast } from "react-toastify";

const Header = () => {
    const { logout } = useAuth();
    const user = getLocalStorageItem("user");
    const info = getLocalStorageItem("info");
    const defaultColor = "#0959b3";
    const [companyColor, setCompanyColor] = useState(
        info ? info.color : defaultColor
    );
    const [fontColor, setFontColor] = useState("black");
    const [companyLogo, setCompanyLogo] = useState(null);
    const location = useLocation();
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(true);
    const isMounted = useRef(true);
    const [imgError, setImgError] = useState(false);
    const { apiKey, msg } = useMemo(() => getGoogleKeyOrMsg(), []);
    
    useEffect(() => {
        let _color = defaultColor;
        if (user && user.company_info) {
            if ([null, "null"].indexOf(user?.company_info?.color) === -1) {
                _color = user?.company_info?.color;
                setCompanyColor(user.company_info.color);
            } else {
                setCompanyColor(defaultColor);
            }

            document.title = `${user.company_info.name} - Vehicle Tracking Services`;

            if (
                user &&
                user.company_info &&
                user.company_info.profile_image &&
                !user.company_info.profile_image.includes("avatar.png")
            ) {
                setCompanyLogo(user.company_info.profile_image);
            }
        }

        setFontColor(getFontColor(_color));
        localStorage.removeItem("last_page");
    }, [user]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Simulate API call
                const userinfo = await get("/api/user");

                // User info handling
                if (userinfo.code === 200) {
                    // Check here
                    if (
                        ["Lock", "Inactive"].includes(userinfo.data.company_info.status)
                    ) {
                        toast.error(
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: userinfo.data.company_info.status_message,
                                }}
                            />
                        );
                        clearLocalStorage();
                        // Add a delay before redirecting
                        setTimeout(() => {
                            window.location.href = "/login"; // Force a full page reload to the login page
                        }, 5000); // 5000 milliseconds = 5 seconds
                    } else {
                        setLocalStorageItem("user", userinfo.data);
                        setLocalStorageItem("info", userinfo.data.company_info);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error.message);
            } finally {
                setLoading(false);
            }
        };

        if (user && isMounted.current) fetchData();
        else setLoading(false);

        return () => {
            // Component is unmounting, update the flag
            isMounted.current = false;
        };
    }, [user]); // Specify getInfo as a dependency

    const isActive = (path) => {
        return location.pathname.includes(path) ? "active" : "";
    };

    const calculateLighterColor = (hexColor, percent) => {
        // Convert hex to RGB
        const r = parseInt(hexColor.slice(1, 3), 16);
        const g = parseInt(hexColor.slice(3, 5), 16);
        const b = parseInt(hexColor.slice(5, 7), 16);

        // Calculate lighter color using HSL
        const hslColor = rgbToHsl(r, g, b);
        const lighterHSL = `hsl(${hslColor[0]}, ${hslColor[1]}%, ${hslColor[2] + percent
            }%)`;

        return lighterHSL;
    };

    const rgbToHsl = (r, g, b) => {
        r /= 255;
        g /= 255;
        b /= 255;

        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h,
            s,
            l = (max + min) / 2;

        if (max === min) {
            h = s = 0;
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
                default:
                    break;
            }

            h /= 6;
        }

        return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)];
    };

    const getFontColor = (color) => {
        // Determine the best font color (black or white) based on the background color
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;
        const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
        return luminance > 128 ? "black" : "white";
    };

    const darkerColor = companyColor && calculateLighterColor(companyColor, 5); // Adjust the percentage as needed
    const lighterColor = companyColor && calculateLighterColor(companyColor, 10); // Adjust the percentage as needed
    const companyColorLighter =
        companyColor && calculateLighterColor(companyColor, 15); // Adjust the percentage as needed

    const handleMenuClick = () => {
        setExpanded(false); // Close the navbar
        if (window.innerWidth < 992) {
            $("#bugericon").click();
        }
    };
    return (
        <>
            <style>
                {`
                    .progress-bar-inner {
                        background-color: ${companyColor} !important;
                    }
                    .navbar .nav-link {
                        color: ${fontColor} !important;
                    }
                    .navbar .dropdown-menu {
                        background-color: ${companyColor} !important;
                        border-radius: 0;
                        margin: 0 !important;
                        padding: 0 !important;
                        // padding-left: 10px !important;
                        border: none !important;
                    }
                    .dropdown-menu .nav-link {
                        padding: 5px 0;
                        padding-left: 10px !important;
                        border-bottom: 1px solid ${lighterColor}
                    }
                    .dropdown-menu .nav-link:hover {
                        background-color: ${lighterColor}
                    }.navbar.navbar-expand-lg.navbar-light {
                        padding: 0;
                        border-top: 3px solid ${lighterColor};
                        position: relative;
                        // z-index: 2222;
                    }
                    .nav-link.active {
                        border-bottom-color: ${fontColor} !important;
                        border-bottom-width: 0px !important;
                        background-color: ${lighterColor}
                    }
                    a.nav-link:hover {
                    text-decoration: none !important;
                    }
                    .spinner{
                        border: 8px ${companyColor} double;
                    }
                    .loading {
                        border-top: 3px ${darkerColor} solid;
                        width:100%;
                        height: 3px;
                        position: absolute;
                        top:0;
                        background:#04acec;
                        animation: loading 3s linear infinite
                    }
                    .spinner{
                        border: 8px ${companyColor} double;
                    }
                    // .card-header {
                    //     background-color: ${companyColorLighter} !important;
                    //     color: ${fontColor} !important
                    // }
                    // .card {
                    //     border-color: ${companyColorLighter} !important
                    // }
                    // .btn-primary {
                    //     background-color: ${companyColorLighter} !important;
                    //     border: none !important;
                    // }

                    .border-color{
                        font-weight: bold;
                        border: 2px solid ${companyColor};
                    }
                    .navbar-toggler {
                        position: relative;
                        bottom: 4px;
                    }
                    .navbar-toggler:focus {
                        box-shadow: none;
                    }
                    
                    #basic-navbar-nav {    
                        padding: 0px 10px;
                    }
                    @media (max-width: 600px) {

                        .dropdown-toggle::after {
                            position: absolute;
                            right: 0;
                            top: 16px;
                        }
                    }
                `}
            </style>
            {loading && <Loader simple={false} />}

            <div className={fontColor === "black" ? " bg-light" : ""}>
                <Navbar expand="lg">
                    <div
                        style={{
                            backgroundColor: "#fff",
                            padding: "0px 0px 0px 10px",
                        }}
                    >
                        <Navbar.Brand
                            as={Link}
                            to={hasPermission("Dashboard") ? "/" : "map"}
                            style={{ maxHeight: "36px" }}
                        >
                            {companyLogo && !imgError ? (
                                <img
                                    src={companyLogo}
                                    alt=""
                                    className="img-fluid"
                                    style={{ maxHeight: "36px" }}
                                    onError={() => setImgError(true)}
                                />
                            ) : user && user.company_info ? (
                                user.company_info.name
                            ) : (
                                "One Track"
                            )}
                        </Navbar.Brand>
                    </div>
                    <Navbar.Toggle
                        aria-controls="basic-navbar-nav"
                        id="bugericon"
                        className="mt-2 me-2"
                        onClick={() => setExpanded(!expanded)}
                    />

                    <Navbar.Collapse
                        id="basic-navbar-nav"
                        style={{ backgroundColor: companyColor, color: fontColor }}
                        expanded={expanded ? "true" : "false"}
                    >
                        <Nav className="mr-auto">
                            {hasPermission("Dashboard") && (
                                <Nav.Link
                                    as={Link}
                                    to="/"
                                    className={isActive("/dashboard")}
                                    onClick={() => handleMenuClick()}
                                >
                                    <FontAwesomeIcon icon={faHome} className="me-1" />
                                    Dashboard
                                </Nav.Link>
                            )}
                            {(hasPermission("List Companies") ||
                                hasPermission("Add Company")) && (
                                    <div className="nav-item dropdown">
                                        <button
                                            className="dropdown-toggle nav-link"
                                            type="button"
                                            id="dropdownMenuButton-companies"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <FontAwesomeIcon icon={faBuildingUser} className="me-1" />
                                            Company Management
                                        </button>
                                        <div
                                            className="dropdown-menu justify-content"
                                            aria-labelledby="dropdownMenuButton-companies"
                                        >
                                            {hasPermission("List Companies") && (
                                                <Link
                                                    onClick={() => handleMenuClick()}
                                                    className={"nav-link " + isActive("/companies")}
                                                    to="/companies"
                                                >
                                                    <FontAwesomeIcon icon={faBuilding} className="me-3" />
                                                    Companies
                                                </Link>
                                            )}
                                            {hasPermission("Add Company") && (
                                                <Link
                                                    onClick={() => handleMenuClick()}
                                                    className={"nav-link " + isActive("/company/create")}
                                                    to="/company/create"
                                                >
                                                    <FontAwesomeIcon icon={faUserPlus} className="me-2" />
                                                    Add Company
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                )}
                            {(hasPermission("List Customers") ||
                                hasPermission("Add Customer")) && (
                                    <div className="nav-item dropdown">
                                        <a
                                            href="javascript:void(0);"
                                            className="dropdown-toggle nav-link"
                                            type="button"
                                            id="dropdownMenuButton-customers"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <FontAwesomeIcon icon={faBuildingUser} className="me-1" />
                                            Customer Management
                                        </a>
                                        <div
                                            className="dropdown-menu justify-content"
                                            aria-labelledby="dropdownMenuButton-customers"
                                        >
                                            <Link
                                                onClick={() => handleMenuClick()}
                                                className={"nav-link " + isActive("/customers")}
                                                to="/customers"
                                            >
                                                <FontAwesomeIcon icon={faBuilding} className="me-3" />
                                                Customers
                                            </Link>
                                            <Link
                                                onClick={() => handleMenuClick()}
                                                className={"nav-link " + isActive("/customer/create")}
                                                to="/customer/create"
                                            >
                                                <FontAwesomeIcon icon={faUserPlus} className="me-2" />
                                                Add Customers
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            {(hasPermission("List Invoices") ||
                                hasPermission("List Payments")) && (
                                    <div className="nav-item dropdown">
                                        <button
                                            className="dropdown-toggle nav-link"
                                            type="button"
                                            id="dropdownMenuButton-accounts"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <FontAwesomeIcon icon={faCashRegister} className="me-2" />
                                            Accounts
                                        </button>
                                        <div
                                            className="dropdown-menu justify-content"
                                            aria-labelledby="dropdownMenuButton-accounts"
                                        >
                                            <Link
                                                onClick={() => handleMenuClick()}
                                                className={"nav-link " + isActive("/invoices")}
                                                to="/invoices"
                                            >
                                                <FontAwesomeIcon icon={faFileInvoice} className="me-3" />
                                                Invoices
                                            </Link>
                                            <Link
                                                onClick={() => handleMenuClick()}
                                                className={"nav-link " + isActive("/payments")}
                                                to="/payments"
                                            >
                                                <FontAwesomeIcon icon={faMoneyBill} className="me-2" />
                                                Payments
                                            </Link>
                                        </div>
                                    </div>
                                )}

                            {(hasPermission("Map") ||
                                hasPermission("List Events") ||
                                hasPermission("List Event Activities") ||
                                hasPermission("List Devices")) && (
                                    <div className="nav-item dropdown">
                                        <button
                                            className="dropdown-toggle nav-link"
                                            type="button"
                                            id="dropdownMenuButton-companies"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <FontAwesomeIcon
                                                icon={isSuperAdmin() ? faHardDrive : faMap}
                                                className="me-1"
                                            />
                                            {isSuperAdmin() ? "Devices" : "Map"}
                                        </button>
                                        <div
                                            className="dropdown-menu justify-content"
                                            aria-labelledby="dropdownMenuButton-companies"
                                        >
                                            {hasPermission("Map") && (
                                                <a
                                                    href="/new-map"
                                                    className={"nav-link " + isActive("/new-map")}
                                                    onClick={handleMenuClick}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faMapLocation}
                                                        className="me-2"
                                                    />
                                                    New Map
                                                </a>
                                            )}

                                            {/* {hasPermission('Map') && (
                                            <a href="/devices-list-socket" className={'nav-link ' + isActive('/devices-list-socket')} onClick={handleMenuClick}>
                                                <FontAwesomeIcon icon={faMapLocation} className="me-2" />
                                                Devices LS
                                            </a>
                                        )} */}

                                            {hasPermission("Map") && (
                                                // <Nav.Link as={Link} to="/map" className={isActive('/map')} onClick={handleMenuClick}>
                                                //     <FontAwesomeIcon icon={faMapLocation} className="me-2" />
                                                //     Map
                                                // </Nav.Link>
                                                <a
                                                    href="/map"
                                                    className={"nav-link " + isActive("/map")}
                                                    onClick={handleMenuClick}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faMapLocation}
                                                        className="me-2"
                                                    />
                                                    Map
                                                </a>
                                            )}
                                            {hasPermission("List Devices") && (
                                                <Nav.Link
                                                    as={Link}
                                                    to="/devices"
                                                    className={isActive("/devices")}
                                                    onClick={handleMenuClick}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faWalkieTalkie}
                                                        className="me-2"
                                                    />
                                                    Devices
                                                </Nav.Link>
                                            )}
                                            {hasPermission("List Events") && (
                                                <Nav.Link
                                                    as={Link}
                                                    to="/events"
                                                    className={isActive("/events")}
                                                    onClick={handleMenuClick}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCalendarCheck}
                                                        className="me-2"
                                                    />
                                                    Events
                                                </Nav.Link>
                                            )}
                                            {hasPermission("List Event Activities") && (
                                                <Nav.Link
                                                    as={Link}
                                                    to="/activities"
                                                    className={isActive("/activities")}
                                                    onClick={handleMenuClick}
                                                >
                                                    <FontAwesomeIcon icon={faListCheck} className="me-2" />
                                                    Activities
                                                </Nav.Link>
                                            )}

                                            {hasPermission("List Geofences") && (
                                                // <Link onClick={() => handleMenuClick()} className={'nav-link ' + isActive('/geofences')} to="/geofences">
                                                //     <FontAwesomeIcon icon={faMapLocationDot} className="me-2" />
                                                //     Geofences
                                                // </Link>
                                                <a
                                                    href="/geofences"
                                                    className={"nav-link " + isActive("/geofences")}
                                                    onClick={handleMenuClick}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faMapLocationDot}
                                                        className="me-2"
                                                    />
                                                    Geofences
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                )}

                            {(hasPermission("List Roles") || hasPermission("List Users")) && (
                                <div className="nav-item dropdown">
                                    <button
                                        className="dropdown-toggle nav-link"
                                        type="button"
                                        id="dropdownMenuButton-user-management"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <FontAwesomeIcon icon={faUsers} className="me-2" />
                                        Users Management
                                    </button>
                                    <div
                                        className="dropdown-menu justify-content"
                                        aria-labelledby="dropdownMenuButton-user-management"
                                    >
                                        <Link
                                            onClick={() => handleMenuClick()}
                                            className={"nav-link " + isActive("/roles")}
                                            to="/roles"
                                        >
                                            <FontAwesomeIcon
                                                icon={faPersonWalkingLuggage}
                                                className="me-2"
                                            />
                                            Roles
                                        </Link>
                                        <Link
                                            onClick={() => handleMenuClick()}
                                            className={"nav-link " + isActive("/users")}
                                            to="/users"
                                        >
                                            <FontAwesomeIcon icon={faAddressBook} className="me-2" />
                                            Users
                                        </Link>
                                    </div>
                                </div>
                            )}

                            {/* Reports  and first hasPErmission only search any of the report is allowed it will show menu*/}
                            {hasPermission(" Report", true) && (
                                <>
                                    <div className="nav-item dropdown">
                                        <button
                                            className="dropdown-toggle nav-link"
                                            type="button"
                                            id="dropdownMenuButton-reports"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <FontAwesomeIcon icon={faChartSimple} className="me-2" />
                                            Reports
                                        </button>
                                        <div
                                            className="dropdown-menu justify-content"
                                            aria-labelledby="dropdownMenuButton-reports"
                                        >
                                            {getAllReports().map(
                                                (report) =>
                                                    hasPermission(report.permission) && (
                                                        <a
                                                            key={report.permission}
                                                            onClick={() => handleMenuClick()}
                                                            className={"nav-link " + isActive(report.path)}
                                                            href={report.path}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={report.icon}
                                                                className="me-2"
                                                            />
                                                            {report.text}
                                                        </a>
                                                    )
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}

                            {(hasPermission("List Dispose Offs") ||
                                hasPermission("SMTP Settings") ||
                                hasPermission("List Event Types") ||
                                hasPermission("List Inventory") ||
                                hasPermission("Manage Inventory") ||
                                hasPermission("List Company Models") ||
                                hasPermission("List Geofences")) && (
                                    <div className="nav-item dropdown text-nowrap">
                                        <button
                                            className="dropdown-toggle nav-link"
                                            type="button"
                                            id="dropdownMenuButton-settings"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <FontAwesomeIcon icon={faCog} className="me-2" />
                                            Settings
                                        </button>
                                        <div
                                            className="dropdown-menu justify-content"
                                            aria-labelledby="dropdownMenuButton-settings"
                                        >
                                            {hasPermission("List Dispose Offs") && (
                                                <Link
                                                    onClick={() => handleMenuClick()}
                                                    className={"nav-link " + isActive("/dispose-offs")}
                                                    to="/dispose-offs"
                                                >
                                                    <FontAwesomeIcon icon={faTv} className="me-2" />
                                                    Monitoring Dispose Off
                                                </Link>
                                            )}

                                            {(hasPermission("List Inventory") ||
                                                hasPermission("Manage Inventory")) && (
                                                    <Link
                                                        onClick={() => handleMenuClick()}
                                                        className={
                                                            "nav-link " + isActive("/inventory-locations")
                                                        }
                                                        to="/inventory-locations"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faLocationArrow}
                                                            className="me-2"
                                                        />
                                                        Locations
                                                    </Link>
                                                )}

                                            {(hasPermission("List Inventory") ||
                                                hasPermission("Manage Inventory")) && (
                                                    <Link
                                                        onClick={() => handleMenuClick()}
                                                        className={"nav-link " + isActive("/model-names")}
                                                        to="/model-names"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faBuildingCircleExclamation}
                                                            className="me-2"
                                                        />
                                                        Models
                                                    </Link>
                                                )}

                                            {(hasPermission("List Inventory") ||
                                                hasPermission("Manage Inventory")) && (
                                                    <Link
                                                        onClick={() => handleMenuClick()}
                                                        className={"nav-link " + isActive("/inventories")}
                                                        to="/inventories"
                                                    >
                                                        <FontAwesomeIcon icon={faWarehouse} className="me-2" />
                                                        Inventory
                                                    </Link>
                                                )}

                                            {hasPermission("SMTP Settings") && (
                                                <Link
                                                    onClick={() => handleMenuClick()}
                                                    className={"nav-link " + isActive("/smtp-settings")}
                                                    to="/smtp-settings"
                                                >
                                                    <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                                                    SMTP Settings
                                                </Link>
                                            )}

                                            {(isCompanyAdmin() ||
                                                hasPermission("List Event Types")) && (
                                                    <Link
                                                        onClick={() => handleMenuClick()}
                                                        className={"nav-link " + isActive("/control-room-events")}
                                                        to="/control-room-events"
                                                    >
                                                        <FontAwesomeIcon icon={faListAlt} className="me-2" />
                                                        Control Room Events
                                                    </Link>
                                                )}

                                            {(isCompanyAdmin() ||
                                                hasPermission("List Event Types")) && (
                                                    <Link
                                                        onClick={() => handleMenuClick()}
                                                        className={"nav-link " + isActive("/mobile-app-notifications")}
                                                        to="/mobile-app-notifications"
                                                    >
                                                        <FontAwesomeIcon icon={faBell} className="me-2" />
                                                        Mobile App Notifications
                                                    </Link>
                                                )}

                                        </div>
                                    </div>
                                )}
                            {hasPermission("List Device Models") && (
                                <Nav.Link
                                    as={Link}
                                    to="/device-models"
                                    className={isActive("/device-models")}
                                    onClick={handleMenuClick}
                                >
                                    <FontAwesomeIcon icon={faCircleInfo} className="me-2" />
                                    Supported Devices
                                </Nav.Link>
                            )}
                        </Nav>
                        <Nav className="navbar-nav ms-auto mb-2 mb-lg-0">
                            {user ? (
                                <div className="nav-item dropdown">
                                    <button
                                        className="dropdown-toggle nav-link"
                                        type="button"
                                        id="dropdownMenuButton-profile-menu"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <FontAwesomeIcon icon={faUser} className="me-2" />
                                        {`${user.role_name}`}
                                    </button>
                                    <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton-profile-menu"
                                        style={{ zIndex: 10000, overflowX: "hidden" }}
                                    >
                                        <Link
                                            onClick={() => handleMenuClick()}
                                            className={"nav-link " + isActive("/profile")}
                                            to="/profile"
                                        >
                                            <FontAwesomeIcon icon={faPersonRifle} className="me-2" />
                                            {`${user.name}`} Profile
                                        </Link>
                                        <Link className="nav-link " onClick={logout}>
                                            <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                                            Logout
                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                <Nav.Link as={Link} to="/login" onClick={handleMenuClick}>
                                    Login
                                </Nav.Link>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
            {user && user.company_info?.status_message && (
                <div
                    className="alert alert-warning p-2 mb-0 rounded-0 text-center"
                    role="alert"
                    dangerouslySetInnerHTML={{ __html: user.company_info.status_message }}
                ></div>
            )}
            {user && !apiKey && msg && (
                <div
                    className="alert alert-warning p-2 rounded-0 text-center"
                    role="alert"
                    dangerouslySetInnerHTML={{ __html: msg }}
                ></div>
            )}
        </>
    );
};

export default Header;
