import { faBellSlash, faBuilding, faGaugeHigh, faListOl, faMapLocationDot, faMoneyBill, faReplyAll, faRoadBarrier, faRoute, faStopCircle, faTram } from "@fortawesome/free-solid-svg-icons";
import { get, post } from "./api";
import { toast } from 'react-toastify';
import './helpers.css';

export const replace_chr_with = (str, which, withChar) => {
    let charsToReplace;
    if (typeof which === 'string') {
        charsToReplace = [which];
    } else if (Array.isArray(which)) {
        charsToReplace = which;
    } else {
        throw new Error('Invalid input for "which". It should be either a string or an array.');
    }

    const escapedChars = charsToReplace.map(char => char.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|');
    const regex = new RegExp(escapedChars, 'g');

    return str.replace(regex, withChar).toLowerCase();
};


export const setLocalStorageItem = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error('Error setting localStorage item:', error.message);
    }
};

// Create a helper function to get an item from localStorage
export const getLocalStorageItem = (key, needParsed = true) => {
    try {
        const item = localStorage.getItem(key);
        return item ? (needParsed ? JSON.parse(item) : item) : null;
    } catch (error) {
        console.error('Error getting localStorage item:', error.message);
        return null;
    }
};
export const clearLocalStorage = () => {
    try {
        localStorage.clear();
    } catch (error) {
        console.error('Error clearing localStorage ', error.message);
        return null;
    }
};
export const removeLocalStorage = (key) => {
    try {
        if (getLocalStorageItem(key)) localStorage.removeItem(key);
    } catch (error) {
        console.error('Error getting localStorage item:', error.message);
        return null;
    }
};
export const getEngineStatus = (selectedVehicle) => {
    // console.log(selectedVehicle.blocked, selectedVehicle?.engineStatus);
    if (selectedVehicle?.blocked === 1 || selectedVehicle?.blocked === '1' || selectedVehicle?.blocked === true || selectedVehicle?.blocked === 'true')
        return false;
    else if (selectedVehicle?.blocked === 0 || selectedVehicle?.blocked === '0' || selectedVehicle?.blocked === false || selectedVehicle?.blocked === 'false')
        return true;

    // else if (selectedVehicle?.engineStatus === 0 || selectedVehicle?.engineStatus === '0' || selectedVehicle?.engineStatus === false || selectedVehicle?.engineStatus === 'false')
    //     return false;
    // else if (selectedVehicle?.engineStatus === 1 || selectedVehicle?.engineStatus === '1' || selectedVehicle?.engineStatus === true || selectedVehicle?.engineStatus === 'true')
    //     return true;
    const cutoffs = ['cut off', 'relay=f', 'stop oil'];
    const restore = ['restore fuel', 'relay=succ', 'supply oil'];
    const str = selectedVehicle?.result ? selectedVehicle?.result.toLowerCase() : '';
    let engine_status = true;

    // Check for any matches in the $cutoffs array
    cutoffs.forEach(cutoff => {
        if (str.includes(cutoff)) {
            engine_status = false;
        }
    });

    // Check for any matches in the $restore array
    restore.forEach(res => {
        if (str.includes(res)) {
            engine_status = true;
        }
    });

    return engine_status;
}

export const getEngineStatusColor = (device) => {
    return getEngineStatus(device);
}

export const durationOptions = [
    // { label: 'Daily', value: 1 },
    { label: 'Weekly', value: '1 week' },
    { label: 'Monthly', value: '1 month' },
    { label: 'Quaterly', value: '3 months' },
    { label: '6 Months', value: '6 months' },
    { label: '1 Year', value: '1 year' },
    { label: '2 Year', value: '2 years' },
];

export const statusOptions = [
    // { label: 'Pending', value: 'Pending' },
    { label: 'Trial', value: 'Trial' },
    { label: 'Active', value: 'Active' },
    { label: 'Lock', value: 'Lock' },
    { label: 'Dead', value: 'Dead' },
];

export const customerStatusOptions = [
    // { label: 'Pending', value: 'Pending' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
    { label: 'Lock', value: 'Lock' },
    { label: 'Grace Period', value: 'Grace Period' },
];

export const chart_filters = [
    { label: 'Today', value: 'today' },
    { label: 'This Week', value: 'this week' },
    { label: 'This Month', value: 'this month' }
];

export const permissionOptions = [
    { label: 'Manage Inventory', value: 'manageInventory' },
    { label: 'SMTP Settings', value: 'smtp_settings' },
    { label: 'Update Google API Key', value: 'update_google_key' },
    // Add more permission options as needed
];
export const paymentModeOptions = [
    { label: 'JazzCash', value: 'JazzCash' },
    { label: 'EasyPaisa', value: 'EasyPaisa' },
    { label: 'Cash', value: 'Cash' },
    { label: 'Bank Transfer', value: 'Bank Transfer' },
    { label: 'Debit/Credit Card', value: 'Debit/Credit Card' },
];
export const networkModeOptions = [
    { label: 'Jazz', value: 'Jazz' },
    { label: 'Zong', value: 'Zong' },
    { label: 'Ufone', value: 'Ufone' },
    { label: 'Telenor', value: 'Telenor' },
    { label: 'Onic', value: 'Onic' }
];

export const cutOffLocationOptions = [
    { label: 'Ignition Cut Off', value: 'Ignition Cut Off' },
    { label: 'Fuel Cut Off', value: 'Fuel Cut Off' },
    { label: 'No Cut Off Installed', value: 'No Cut Off Installed' },
];

export const preferencesOptions = [
    { label: 'Reports', value: 'reports' },
    { label: 'Live Tracking', value: 'livetracking' },
    { label: 'Show Alerts', value: 'showalerts' },
    { label: 'Payments', value: 'payment' },
    // { label: 'Geo fence', value: 'geoFence' },
    // { label: 'Overspeed', value: 'overspeed', takeValue: true },
    // { label: 'If No Response, Kill immediately?', value: 'noResponseKill' },
];

export const autokillOptions = [
    { label: 'Geo fence', value: 'geoFence' },
    { label: 'Overspeed', value: 'overspeed', takeValue: true },
    { label: 'If No Response, Kill immediately?', value: 'noResponseKill' },
];

export const getPreferencesLabel = (preference, what) => {
    const matchingOption = preferencesOptions.find(option => option.value === preference);
    return matchingOption ? matchingOption[what] : null;
};

export const privilegesOptions = [
    { label: 'Kill the Engine', value: 'killengine' },
    { label: 'Define Geofence', value: 'definegeofence' },
    { label: 'Alert Setting', value: 'alertsetting' }
];
export const alarmStatuses = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Resolved', value: 'Resolved' },
];
export const getAllEvents = (event) => {
    /*
    public static final String ALARM_GENERAL = "general";
    public static final String ALARM_SOS = "sos";
    public static final String ALARM_VIBRATION = "vibration";
    public static final String ALARM_MOVEMENT = "movement";
    public static final String ALARM_LOW_SPEED = "lowspeed";
    public static final String ALARM_OVERSPEED = "overspeed";
    public static final String ALARM_FALL_DOWN = "fallDown";
    public static final String ALARM_LOW_POWER = "lowPower";
    public static final String ALARM_LOW_BATTERY = "lowBattery";
    public static final String ALARM_FAULT = "fault";
    public static final String ALARM_POWER_OFF = "powerOff";
    public static final String ALARM_POWER_ON = "powerOn";
    public static final String ALARM_DOOR = "door";
    public static final String ALARM_LOCK = "lock";
    public static final String ALARM_UNLOCK = "unlock";
    public static final String ALARM_GEOFENCE = "geofence";
    public static final String ALARM_GEOFENCE_ENTER = "geofenceEnter";
    public static final String ALARM_GEOFENCE_EXIT = "geofenceExit";
    public static final String ALARM_GPS_ANTENNA_CUT = "gpsAntennaCut";
    public static final String ALARM_ACCIDENT = "accident";
    public static final String ALARM_TOW = "tow";
    public static final String ALARM_IDLE = "idle";
    public static final String ALARM_HIGH_RPM = "highRpm";
    public static final String ALARM_ACCELERATION = "hardAcceleration";
    public static final String ALARM_BRAKING = "hardBraking";
    public static final String ALARM_CORNERING = "hardCornering";
    public static final String ALARM_LANE_CHANGE = "laneChange";
    public static final String ALARM_FATIGUE_DRIVING = "fatigueDriving";
    public static final String ALARM_POWER_CUT = "powerCut";
    public static final String ALARM_POWER_RESTORED = "powerRestored";
    public static final String ALARM_JAMMING = "jamming";
    public static final String ALARM_TEMPERATURE = "temperature";
    public static final String ALARM_PARKING = "parking";
    public static final String ALARM_BONNET = "bonnet";
    public static final String ALARM_FOOT_BRAKE = "footBrake";
    public static final String ALARM_FUEL_LEAK = "fuelLeak";
    public static final String ALARM_TAMPERING = "tampering";
    public static final String ALARM_REMOVING = "removing";
    */
    const user = getLocalStorageItem('user');

    const events = user.company_info.event_types || ['powerCut', 'geoFence', 'overspeed', 'deviceOverspeed', 'vibration', 'accident', 'tampering', 'lowBattery', 'hardBraking'];
    return event ? getEventName(event) : events;
}
export const getEventTypes = (event = null) => {
    const events = [
        // "commandResult",
        "deviceOnline",
        "deviceUnknown",
        "deviceOffline",
        "deviceInactive",
        "deviceMoving",
        "deviceStopped",
        "deviceOverspeed",
        // "deviceFuelDrop",
        // "deviceFuelIncrease",
        "geofenceEnter",
        "geofenceExit",
        "alarm",
        "unusualIgnitionOn",
        "ignitionOn",
        "ignitionOff",
        // "maintenance",
        // "textMessage",
        // "driverChanged",
        // "media",
    ];
    return event === null ? events : events[event];
};
export const getEventTypeName = (event) => {
    const events = {
        // "commandResult": "Command result",
        "deviceOnline": "Status online",
        "deviceUnknown": "Status unknown",
        "deviceOffline": "Status offline",
        "deviceInactive": "Device inactive",
        "deviceMoving": "Device moving",
        "deviceStopped": "Device stopped",
        "deviceOverspeed": "Geofence Speed limit ",
        // "deviceFuelDrop": "Fuel drop" ,
        // "deviceFuelIncrease": "Fuel increase" ,
        "geofenceEnter": "Geofence entered",
        "geofenceExit": "Geofence exited",
        "alarm": "Alarm",
        "unusualIgnitionOn": "Unusual Ignition on",
        "ignitionOn": "Ignition on",
        "ignitionOff": "Ignition off",
        // "maintenance": "Maintenance required" ,
        // "textMessage": "Text message received" ,
        // "driverChanged": "Driver changed" ,
        // "media": "Media",
    }

    return events[event];
};

export const getEventName = (event) => {
    return event;
    // switch (event) {
    //     case "powerCut":
    //         return "Power Cut";

    //     case "geoFence":
    //         return "Geo Fence";

    //     case "overspeed":
    //         return "Overspeed";

    // }
}

export const getFilters = (key) => {
    // Always include 'All' filter
    let filters = ['All'];

    // Add dynamic filters based on the active tab
    if (key === 'vehicles') {
        const deviceStatuses = getDeviceStatuses().map(camelCaseDeviceStatus);
        filters = filters.concat(deviceStatuses);

        // Add "cut off" status to the filters
        filters.push('Cut Off'); // Add "cut off" as a filter option
        // filters.push('Release'); // Ensure this matches the casing used in your application
    } else if (key === 'events') {
        // Get the user's company info from local storage
        const user = getLocalStorageItem('user');

        // Get the event types from the user's company info
        const eventTypes = user.company_info.event_types.map(camelCaseToSentence);

        // Add the event types to the filters
        filters = filters.concat(eventTypes);
    }

    return filters;
};

export const getStatusBadge = (status) => {
    let html = '';
    switch (status) {
        case 'Pending':
        case 'unknown':
        case 'Due':
        case 'Dispose Off':
        case 'Trial':
            html = `<span class="badge bg-warning">${status}</span>`;
            break;
        case 'Partial Paid':
        case 'idle':
        case 'NR':
        case 'Grace Period':
            html = `<span class="badge bg-info">${status}</span>`;
            break;
        case 'Unpaid':
        case 'Inactive':
        case 'offline':
        case 'Used':
        case 'Occupied':
            html = `<span class="badge bg-danger">${status}</span>`;
            break;
        case 'Paid':
        case 'Active':
        case 'online':
        case 'Resolved':
        case 'Available':
            html = `<span class="badge bg-success">${status}</span>`;
            break;
        case 'Lock':
        case 'Dead':
            html = `<span class="badge bg-secondary">${status}</span>`;
            break;
        case 'Off':
            html = `<span class="status-badge text-white" style="background:#ff1d1d">${status}</span>`;
            break;
        case 'On':
            html = `<span class="status-badge text-white" style="background:#2ebf2e">${status}</span>`;
            break;
        default:
            html = `<span class="badge bg-success">${status}</span>`;
    }
    return html;
}
// Function to convert a file to base64
export const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        if (!file) {
            resolve(null);  // Return null if no file is provided
            return;
        }

        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result.split(',')[1]);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsDataURL(file);
    });
};
export const hasPermission = (permission, some_check = false) => {
    const user = getLocalStorageItem('user');

    if (!user || !user.permissions) return false;

    if (some_check) {
        return user.permissions.some(p => p.includes(permission));
    } else {
        return user.permissions.includes(permission);
    }
};


export const getUserRole = () => {
    const user = getLocalStorageItem('user');
    return user && user.role_name;
}
export const getCompanyId = () => {
    const user = getLocalStorageItem('user');
    return user && user.company_id;
}
export const isSuperAdmin = () => {
    const role = getUserRole();
    return role && role === 'Super Admin';
}
export const isSuperAdminUser = () => {
    return getCompanyId() === null;
}
export const isCompanyAdmin = () => {
    const role = getUserRole();
    return role && role === 'Company Admin';
}
export const isMonitoringAgent = () => {
    const role = getUserRole();
    return role && role === 'Monitoring Agent';
}
export const isCompanyAdminUser = () => {
    return getCompanyId() !== null;
}

export const capitalizeEveryWord = (str) => {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const replaceInString = (str, withChr, whatChr) => {
    const regex = new RegExp(withChr, 'g');
    return str.replace(regex, whatChr);
};

export const getCustomerInfo = (obj) => {
    if (obj.device !== undefined) {
        return obj.device.customer.name + ' - ' + obj.device.make + ' - ' + obj.device.model;
    }
    else if (obj.customer && obj.vehicle) {
        return obj.customer.name + ' - ' + obj?.vehicle.make + ' - ' + obj?.vehicle.model;
    } else {
        return '';
    }
}

export const date_format = (date, time_needed = true) => {
    if (!date || date === "" || date === undefined)
        return '';
    // return date;
    const options = time_needed ? {
        day: '2-digit',    // Two-digit day
        month: 'short',    // Short month name (e.g., Jan, Feb, Mar)
        year: 'numeric',   // Four-digit year
        hour: '2-digit',   // Two-digit hour (00-23)
        minute: '2-digit', // Two-digit minute (00-59)
        second: '2-digit', // Two-digit second (00-59)
        hour12: false,     // 24-hour format
    } : {
        day: '2-digit',    // Two-digit day
        month: 'short',    // Short month name (e.g., Jan, Feb, Mar)
        year: 'numeric',   // Four-digit year
    };;

    return new Date(date).toLocaleDateString('en-PK', options);
};

export const date_time_format = (date, time_needed = true, hour12 = true, monthNumeric = false) => {
    if (!date || date === "" || date === undefined)
        return '';
    const dateOptions = {
        day: '2-digit',    // Two-digit day
        month: monthNumeric ? '2-digit' : 'short',  // Two-digit month or short month name
        year: 'numeric',   // Four-digit year
    };

    const timeOptions = {
        hour: '2-digit',   // Two-digit hour (01-12)
        minute: '2-digit', // Two-digit minute (00-59)
        second: '2-digit', // Two-digit second (00-59)
        hour12: hour12,    // 12-hour format based on passed parameter
    };

    let formattedDate = new Date(date).toLocaleDateString('en-PK', dateOptions);
    let formattedTime = time_needed ? new Date(date).toLocaleTimeString('en-PK', timeOptions) : '';

    // Replace slashes with hyphens
    formattedDate = formattedDate.replace(/\//g, '-');

    // Capitalize AM/PM
    if (hour12) {
        formattedTime = formattedTime.replace(/am|pm/g, match => match.toUpperCase());
    }

    return formattedDate + ' ' + formattedTime;
};

const isMatch = (value, searchTerm) => {
    const stringValue = String(value);
    if (typeof value === 'string') {
        return stringValue.toLowerCase().includes(searchTerm.toLowerCase());
    } else if (typeof value === 'number') {
        return stringValue.includes(String(searchTerm));
    }
    return false;
};

export const searchObject = (obj, searchTerm) => {
    return Object.entries(obj).some(([key, value]) => isMatch(value, searchTerm));
};

export const deepSearch = (obj, searchTerm) => {
    return Object.entries(obj).some(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
            return searchObject(value, searchTerm);
        }
        return isMatch(value, searchTerm);
    });
};

export const get_currency = () => {
    return 'Rs.';
}
export const number_to_price = (number) => {
    if (!number) return get_currency() + 0;
    return get_currency() + `${number}`;
}
export const convert_days = (days) => {
    days = parseInt(days);
    let type = 'months';
    let my = 0;

    switch (days) {
        case 1:
            type = 'day';
            my = 1;
            break;
        case 30:
            type = 'month';
            my = 1;
            break;
        case 180:
            type = 'months';
            my = 6;
            break;
        case 365:
            type = 'year';
            my = 1;
            break;
        case 730:
            type = 'years';
            my = 2;
            break;
        default:
            if (days > 365) {
                my = Math.ceil(days / 365);
                type = 'years';
            } else {
                my = Math.ceil(days / 30);
            }
            break;
    }

    return `${my} ${type}`;
};


export const is_empty_object = (obj) => {
    return Object.keys(obj).length === 0;
}

export const convertMillisecondsToHoursMinutes = (milliseconds) => {
    let totalSeconds = milliseconds / 1000;
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    return `${hours} h ${minutes} m`;
}
export const convertSecondsToHoursMinutes = (totalSeconds) => {
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${hours} h ${minutes} m ${seconds} s`;
}

export const formatNumericHours = (value) => {
    const hours = Math.floor(value / 3600000);
    const minutes = Math.floor((value % 3600000) / 60000);
    return `${hours} h ${minutes} m`;
};


export const convert_seconds_to_hours = (seconds) => {
    return (seconds / 3600).toFixed(2) + ' hours';
}
export const convertMilliseconds = (milliseconds) => {
    const seconds = milliseconds / 1000; // Convert milliseconds to seconds

    if (seconds < 60) {
        return seconds + ' seconds';
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        return minutes + ' minutes';
    } else if (seconds < 86400) {
        const hours = Math.floor(seconds / 3600);
        return hours + ' hours';
    } else if (seconds < 2592000) {
        const days = Math.floor(seconds / 86400);
        return days + ' days';
    } else {
        const months = Math.floor(seconds / 2592000); // Assuming 30 days in a month
        return months + ' months';
    }
}
export const convertSeconds = (seconds) => {

    if (seconds < 60) {
        return seconds + ' seconds';
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        return minutes + ' minutes';
    } else if (seconds < 86400) {
        const hours = Math.floor(seconds / 3600);
        return hours + ' hours';
    } else if (seconds < 2592000) {
        const days = Math.floor(seconds / 86400);
        return days + ' days';
    } else {
        const months = Math.floor(seconds / 2592000); // Assuming 30 days in a month
        return months + ' months';
    }
}


export const convert_kn_to_km = (speed) => {
    return (1.852 * speed).toFixed(2) + ' Kph';
}

export const convert_m_to_km = (meters) => {
    return (meters / 1000).toFixed(2) + ' KM';
}

export const getDeviceStatuses = () => {
    return ['offline', 'NR', 'no-location', 'unknown', 'stopped', 'moving', 'parked'];
}

export const camelCaseDeviceStatus = (status) => {
    if (status === 'NR') {
        return status;
    } else if (status.includes('-')) {
        return status.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    } else {
        return status.charAt(0).toUpperCase() + status.slice(1);
    }
}

export const getMarkerColor = (className) => {
    let color = '';
    switch (className) {
        case 'offline':
            color = 'orange';
            break;
        case 'idle':
        case 'NR':
            color = '#2ebfec';
            break;
        case 'unknown':
            color = 'rgb(152, 151, 151)';
            break;
        case 'stopped':
            color = 'rgb(244, 244, 28)';
            break;
        case 'moving':
            color = 'rgb(14, 202, 14)';
            break;
        case 'parked':
            color = 'rgb(227, 6, 6)';
            break;
        default:
            color = '#000000'; // Default color
            break;
    }

    return color;
}

export const getEventListCardColor = (event_type) => {
    let color = '';
    switch (event_type) {
        case 'powerCut':
            color = 'rgb(255, 234, 234)';
            break;
        case 'overspeed':
            color = 'rgb(234, 248, 255)';
            break;
        case 'geofenceEnter':
            color = 'rgb(255, 234, 234)';
            break;
        case 'geofenceExit':
            color = 'rgb(239, 234, 255)';
            break;
        case 'vibration':
            color = 'rgb(246, 210, 236)';
            break;
        case 'deviceOverspeed':
            color = 'rgb(252, 171, 229)';
            break;
        case 'unusualIgnitionOn':
            color = '#f7635c';
            break;
        default:
            color = '#ffffff'; // Default color
            break;
    }

    return color;
}

export const getVehicleStatusColor = (vehicle_status) => {
    let html = '';
    switch (vehicle_status) {
        case 'Offline':
            html = `<span class="status-badge text-white" style="background:rgb(199, 149, 56)">${vehicle_status}</span>`;
            break;
        case 'Online':
            html = `<span class="status-badge text-white" style="background:#2ebf2e">${vehicle_status}</span>`;
            break;
        case 'Idle':
        case 'NR':
            html = `<span class="status-badge text-white" style="background:#2ebfec">${vehicle_status}</span>`;
            break;
        case 'Unknown':
            html = `<span class="status-badge text-white" style="background:rgb(152, 151, 151)">${vehicle_status}</span>`;
            break;
        case 'Stopped':
            html = `<span class="status-badge text-white" style="background:rgb(244, 244, 28)">${vehicle_status}</span>`;
            break;
        case 'Moving':
            html = `<span class="status-badge text-white" style="background:rgb(14, 202, 14)">${vehicle_status}</span>`;
            break;
        case 'Parked':
            html = `<span class="status-badge text-white" style="background:rgb(227, 6, 6)">${vehicle_status}</span>`;
            break;
        case 'No-location':
            html = `<span class="status-badge text-white" style="background:orange">${vehicle_status}</span>`;
            break;
        default:
            html = `<span class="status-badge text-white" style="background:#000000">${vehicle_status}</span>`;
            break;
    }
    return html;
}

export const getVSColor = (vehicle_status) => {
    let html = '';
    switch (vehicle_status) {
        case 'offline':
            html = `<span class="status-circle" style="background:rgb(199, 149, 56)"></span>`;
            break;
        case 'online':
            html = `<span class="status-circle" style="background:#2ebf2e"></span>`;
            break;
        case 'idle':
        case 'NR':
            html = `<span class="status-circle" style="background:#2ebfec"></span>`;
            break;
        case 'unknown':
            html = `<span class="status-circle" style="background:rgb(152, 151, 151)"></span>`;
            break;
        case 'stopped':
            html = `<span class="status-circle" style="background:rgb(244, 244, 28)"></span>`;
            break;
        case 'moving':
            html = `<span class="status-circle" style="background:rgb(14, 202, 14)"></span>`;
            break;
        case 'parked':
            html = `<span class="status-circle" style="background:rgb(227, 6, 6)"></span>`;
            break;
        case 'no-location':
            html = `<span class="status-circle" style="background:orange"></span>`;
            break;
        default:
            html = `<span class="status-circle" style="background:#000000"></span>`;
            break;
    }
    return html;
}

export const getAllReports = () => {
    return [
        { permission: 'Routes Report', icon: faRoute, text: 'Routes', api_path: 'routes', path: '/reports/routes' },
        { permission: 'Replay Report', icon: faReplyAll, text: 'Replay', api_path: 'replay', path: '/reports/replay' },
        { permission: 'Summary Report', icon: faListOl, text: 'Summary', api_path: 'summary', path: '/reports/summary' },
        { permission: 'Event Report', icon: faBellSlash, text: 'Events', api_path: 'events', path: '/reports/event' },
        { permission: 'Stop Report', icon: faStopCircle, text: 'Parked', api_path: 'stops', path: '/reports/stops' },
        { permission: 'Trip Report', icon: faTram, text: 'Trips', api_path: 'trips', path: '/reports/trips' },
        { permission: 'NR Report', icon: faRoadBarrier, text: 'NR', api_path: 'nr', path: '/reports/nr' },
        { permission: 'Companies Report', icon: faBuilding, text: 'Companies', path: '/reports/companies' },
        { permission: 'Payments Report', icon: faMoneyBill, text: 'Payments', path: '/reports/payments' },
        { permission: 'Unusual Activity Report', icon: faMoneyBill, text: 'Unusual Activity', path: '/reports/unusual-activity' },
        { permission: 'Tracker Model Report', icon: faMoneyBill, text: 'Tracker Model', path: '/reports/tracker-model' },
        { permission: 'Idle Report', icon: faMoneyBill, text: 'Idle', path: '/reports/idle' },
        { permission: 'GeoFence Report', icon: faMapLocationDot, text: 'GeoFence', path: '/reports/geofence' },
        { permission: 'Speed Report', icon: faGaugeHigh, text: 'Speed', path: '/reports/speed' },
    ]
};
export const getExportTypes = () => {
    return [
        { value: 'html', label: "HTML" },
        { value: 'csv', label: "CSV" },
        { value: 'pdf', label: "PDF" },
    ]
};

export const get_google_map_link = (lat, long, address = null) => {

    if (lat === 0 || long === 0) return 'N/A';

    const displayText = address ? address : `${lat}, ${long}`;
    return (
        <a href={`https://www.google.com/maps?q=${lat},${long}&t=m&hl=en`} className="link" target="_blank" rel="noreferrer">
            {displayText}
        </a>
    );
}

export const handleShowAddress = async (e, pid, latitude, longitude, underline = true, linkhref = true) => {
    if (e?.target) {
        var underline_class = 'text-decoration-none';
        if (underline) {
            underline_class = 'text-underline';
        }
        const res = await handleShowAddressApi(pid, latitude, longitude);
        // console.log(res, 'res');

        const link = document.createElement('a');
        if (linkhref) {
            link.href = `https://www.google.com/maps?q=${latitude},${longitude}&t=m&hl=en`;
        }
        link.className = `text-dark ${underline_class}`;
        link.target = '_blank';
        link.rel = 'noreferrer';
        link.text = res || '';

        e?.target?.closest('td').append(link);
        e?.target?.remove();
    }
};

export const handleShowAddressApi = async (pid, latitude, longitude) => {
    try {
        const res = await get(`/api/reports/show-address?pid=${pid}&latitude=${latitude}&longitude=${longitude}`);
        if (res.code === 200) {
            if (res.data) {
                if (res.data.address) {
                    return res.data.address;
                }
            }
        } else {
            toast.error(res.data.error.message)
        }
    } catch (error) {
        toast.error(error.message)
    }
};

// Save user trail
export const saveUserTrail = async (data = {}) => {
    try {

        const response = await post(`/api/log-report`, data);
    } catch (error) {
        console.log('Something went wrong. Unable to save user logs');
    }
}

export const camelCaseToSentence = (text) => {
    if (text) {
        let result = text.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }
    return '';
}


export const formatPercentage = (value) => value != null ? `${value}%` : '0';

export const getBatteryColor = (batteryLevel, charge) => {
    let color = '';
    if (batteryLevel > 70) {
        color = charge ? 'green' : 'lightgreen';
    } else if (batteryLevel > 30) {
        color = charge ? 'orange' : 'yellow';
    } else {
        color = charge ? 'red' : 'darkred';
    }
    return color;
}

export const getIgnitionColor = (ignition) => {
    return ignition ? 'red' : 'green';
}

//Handle Date Range Filter
export const manualHandleDateRangeFilter = (dateRange, dateObj = null) => {
    let startDate = null;
    let endDate = new Date(); // Default to today

    switch (dateRange) {
        case 'today':
            startDate = new Date();
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            break;
        case 'yesterday':
            startDate = new Date();
            startDate.setDate(startDate.getDate() - 1);
            startDate.setHours(0, 0, 0, 0);
            endDate = new Date(startDate);
            endDate.setHours(23, 59, 59, 999);
            break;
        case 'last_2_days':
            startDate = new Date();
            startDate.setDate(startDate.getDate() - 1);
            startDate.setHours(0, 0, 0, 0);
            break;
        case 'last_7_days':
            startDate = new Date();
            startDate.setDate(startDate.getDate() - 6);
            startDate.setHours(0, 0, 0, 0);
            break;
        case 'last_30_days':
            startDate = new Date();
            startDate.setDate(startDate.getDate() - 29);
            startDate.setHours(0, 0, 0, 0);
            break;
        case 'this_month':
            startDate = new Date();
            startDate.setDate(1);
            startDate.setHours(0, 0, 0, 0);
            endDate = new Date();
            endDate.setMonth(endDate.getMonth() + 1, 0);
            endDate.setHours(23, 59, 59, 999);
            break;
        case 'last_month':
            startDate = new Date();
            startDate.setMonth(startDate.getMonth() - 1, 1);
            startDate.setHours(0, 0, 0, 0);
            endDate = new Date();
            endDate.setDate(0);
            endDate.setHours(23, 59, 59, 999);
            break;
        case 'last_6_month':
            startDate = new Date();
            startDate.setMonth(startDate.getMonth() - 6, 1);
            startDate.setHours(0, 0, 0, 0);
            break;
        case 'this_year':
            startDate = new Date();
            startDate.setMonth(0, 1);
            startDate.setHours(0, 0, 0, 0);
            endDate = new Date();
            endDate.setMonth(11, 31);
            endDate.setHours(23, 59, 59, 999);
            break;
        case 'last_year':
            startDate = new Date();
            startDate.setFullYear(startDate.getFullYear() - 1); // Subtract one year
            startDate.setMonth(0, 1);
            startDate.setHours(0, 0, 0, 0);

            endDate = new Date();
            endDate.setFullYear(endDate.getFullYear() - 1); // Subtract one year
            endDate.setMonth(11, 31);
            endDate.setHours(23, 59, 59, 999);
            break;

        case 'custom':
            if (dateObj) {
                startDate = new Date(dateObj.startDate);
                endDate = new Date(dateObj.endDate);
            }
            break;
        default:
            startDate = null;
            endDate = null;
    }

    return { startDate, endDate };
};
export const engineStatus = (ignition) => {
    return (ignition === true || ignition === "true" || ignition == '1')
}

// export const GSMSignal = (rssi) => {
//     if (rssi >= -70) {
//         return "Excellent";
//     } else if (rssi >= -85) {
//         return "Good";
//     } else if (rssi >= -100) {
//         return "Fair";
//     } else if (rssi >= -110) {
//         return "Poor";
//     } else {
//         return "No Signal";
//     }
// };

// Helper function to normalize RSSI values
export function normalizeRssi(rssi) {
    if (rssi >= 0 && rssi <= 5) {
        return rssi / 5;
    } else if (rssi >= 0 && rssi <= 100) {
        return rssi / 100;
    } else if (rssi >= 0 && rssi <= 255) {
        return rssi / 255;
    } else {
        return 0;
    }
}

// Function to convert normalized RSSI to GSM signal quality
export const normalizedToGSM = (normalizedRSSI) => {
    if (normalizedRSSI >= 0.8) {
        return "Excellent";
    } else if (normalizedRSSI >= 0.6) {
        return "Good";
    } else if (normalizedRSSI >= 0.4) {
        return "Fair";
    } else if (normalizedRSSI >= 0.2) {
        return "Poor";
    } else {
        return "No Signal";
    }
};

// Function to convert RSSI to specific labels for 0 to 5 range
export const rssiToLabel = (rssi) => {
    switch (rssi) {
        case 0:
            return "No Signal";
        case 1:
            return "Poor";
        case 2:
            return "Weak";
        case 3:
            return "Normal";
        case 4:
            return "Good";
        case 5:
            return "Excellent";
        default:
            return "-";
    }
};

export const formatCourse = (value) => {
    const courseValues = ['\u2191', '\u2197', '\u2192', '\u2198', '\u2193', '\u2199', '\u2190', '\u2196'];
    const directions = ['N°', 'NE°', 'E°', 'SE°', 'S°', 'SW°', 'W°', 'NW°'];
    let normalizedValue = (value + 45 / 2) % 360;
    if (normalizedValue < 0) {
        normalizedValue += 360;
    }
    const index = Math.floor(normalizedValue / 45);
    return {
        arrow: courseValues[index],
        direction: directions[index]
    };
};

export const timeSince = (date) => {
    const now = new Date();
    const past = new Date(date);
    const seconds = Math.floor((now - past) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
        return interval === 1 ? `${interval} Year` : `${interval} Years`;
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
        return interval === 1 ? `${interval} Month` : `${interval} Months`;
    }
    interval = Math.floor(seconds / 604800);
    if (interval >= 1) {
        return interval === 1 ? `${interval} Week` : `${interval} Weeks`;
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
        return interval === 1 ? `${interval} Day` : `${interval} Days`;
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
        return interval === 1 ? `${interval} Hour` : `${interval} Hours`;
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
        return interval === 1 ? `${interval} Minute` : `${interval} Minutes`;
    }
    return seconds === 1 ? `${seconds} Second` : `${seconds} Seconds`;
};

export const getGoogleKeyOrMsg = () => {
    if (isSuperAdmin() || isSuperAdminUser()) return { apiKey: '', msg: null };

    const user = getLocalStorageItem("user");
    const apiKey = user?.company_info?.google_map_api_key || null;//process.env.REACT_APP_GOOGLE_API_KEY;
    let msg = null;
    if (!apiKey) {
        msg = 'Missing Google API key, please contact administrator.';
        if (hasPermission('Update Google API Key')) {
            msg = `Please update Google API key <a href="/profile" target="_blank">here</a>`;
        }
    }
    return { apiKey: apiKey, msg: msg };
};


export const getTimeDifference = (deviceTime) => {
    const deviceDate = new Date(deviceTime);
    const currentDate = new Date();
    const diffMs = currentDate - deviceDate;

    const diffSecs = Math.floor(diffMs / 1000);
    const diffMins = Math.floor(diffSecs / 60);
    const diffHrs = Math.floor(diffMins / 60);
    const diffDays = Math.floor(diffHrs / 24);

    const days = diffDays;
    const hours = diffHrs % 24;
    const minutes = diffMins % 60;
    const seconds = diffSecs % 60;

    return `${days > 0 ? days + ' d ' : ''}${hours > 0 ? hours + ' h ' : ''}${minutes > 0 ? minutes + ' min ' : ''}${seconds > 0 ? seconds + ' s' : ''}`.trim();
}

export const camelCaseToDevice = (text) => {
    if (text) {
        // Split the text into words
        let words = text.split(' ');
        // Capitalize the first letter of the first word only if it's not already uppercase
        if (words[0] !== words[0].toUpperCase()) {
            words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
        }
        // Join the words back into a single string
        return words.join(' ');
    }
    return '';
}
